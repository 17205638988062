// src/components/Navbar.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(searchQuery);
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <Link to="/" className="navbar-logo">colorpalettes.io</Link>
      </div>
      <div className="navbar-right">
        <ul className="navbar-links">
        <li><Link to="/color">All Colors</Link></li>
        <li><Link to="/color-picker">Color Picker</Link></li>
        <li><Link to="/color-palette-generator">Color Palette Generator</Link></li>
      </ul>
      </div>
    </nav>
  );
};

export default Navbar;
