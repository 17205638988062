import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './AllColorsPage.css';

function ColorsAToZPage() {
  const [colors, setColors] = useState([]);

  useEffect(() => {
    axios.get('/api/colors')
      .then(response => {
        setColors(response.data.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch(error => {
        console.error("There was an error fetching the colors!", error);
      });
  }, []);

  const getTextColor = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 125 ? '#333' : 'white';
  };

  return (
    <div className="all-colors-page">
      <h1>Colors A to Z</h1>
      <h4>A list of colors in alphabetical order</h4>
      <div className="color-section">
        <div className="color-grid">
          {colors.map(color => (
            <div key={color._id} className="color-swatch" style={{ backgroundColor: color.hex }}>
              <Link to={`/color/${color.name.toLowerCase().replace(/\s+/g, '-')}`} className="color-link" style={{ color: getTextColor(color.hex) }}>
                <div className="color-name">{color.name}</div>
                <div className="color-hex">{color.hex}</div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ColorsAToZPage;
