import React from 'react';
import { Link } from 'react-router-dom';
import './WarmColorPalettes.css';

const WarmColorPalettes = ({ palettes }) => {
  return (
    <div className="warm-color-palettes">
      <h1>Warm Color Palettes</h1>
      <div className="palettes-container">
        {palettes.map((palette) => (
          <div key={palette._id} className="palette-card">
            <h4 className="palette-name">
              <Link to={`/palette/${palette.slug}`}>{palette.name}</Link>
            </h4>
            <div className="palette-colors">
              {palette.colors.map((color, index) => (
                <div key={index} className="color-block" style={{ backgroundColor: color.hex }}>
                  <p className="color-name" style={{ color: getTextColor(color.hex) }}>{color.name}</p>
                  <p className="color-hex" style={{ color: getTextColor(color.hex) }}>{color.hex}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const getTextColor = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 125 ? '#333' : 'white';
};

export default WarmColorPalettes;
