import React, { useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';
import Loader from './components/Loader'; // Import the CSS Loader
import WarmColorPalettes from './components/WarmColorPalettes'; 
import ColorPicker from './components/ColorPicker'; // Import ColorPicker
import HamburgerMenu from './components/HamburgerMenu';
import AllColorsPage from './components/AllColorsPage';
import AllColorsAtoZPage from './components/AllColorsAtoZPage'; // Import new AllColorsAtoZPage
import ColorPaletteGenerator from './components/ColorPaletteGenerator'; 
const HomePage = lazy(() => import('./components/HomePage'));
const PalettePage = lazy(() => import('./components/PalettePage'));
const ColorPage = lazy(() => import('./components/ColorPage'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TagPage = lazy(() => import('./components/TagPage'));
const NotFoundPage = lazy(() => import('./components/NotFoundPage'));

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <Loader />
  </div>
);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children; 
  }
}

const App = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <Router>
      <div className="App">
        <Navbar onSearch={handleSearch} />
        <ErrorBoundary>
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="/" element={<HomePage searchQuery={searchQuery} />} />
              <Route path="/palette/:slug" element={<PalettePage />} />
              <Route path="/color/:colorSlug" element={<ColorPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/color" element={<AllColorsPage />} />
              <Route path="/color-a-to-z" element={<AllColorsAtoZPage />} /> {/* Add new route */}
              <Route path="/tag/:tag" element={<TagPage />} />
              <Route path="*" element={<NotFoundPage />} />
              <Route path="/warm-color-palettes" element={<WarmColorPalettes />} />
              <Route path="/color-picker" element={<ColorPicker />} />
              <Route path="/color-palette-generator" element={<ColorPaletteGenerator />} /> 
            </Routes>
          </Suspense>
        </ErrorBoundary>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
