import React, { useState, useEffect } from 'react';
import chroma from 'chroma-js';
import './ColorPaletteGenerator.css';

const ColorPaletteGenerator = () => {
  const [baseColor, setBaseColor] = useState('#3498db');
  const [palettes, setPalettes] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isValidHex(baseColor)) {
      generatePalettes(baseColor);
    } else {
      setError('Invalid hex color code');
    }
  }, [baseColor]);

  const isValidHex = (hex) => {
    const sanitizedHex = hex.startsWith('#') ? hex : `#${hex}`;
    return /^#([0-9A-F]{3}){1,2}$/i.test(sanitizedHex);
  };

  const generatePalettes = (color) => {
    try {
      const sanitizedColor = color.startsWith('#') ? color : `#${color}`;
      console.log('Sanitized color:', sanitizedColor);

      const analogous = chroma.scale([chroma(sanitizedColor).set('hsl.h', '-30').hex(), sanitizedColor, chroma(sanitizedColor).set('hsl.h', '+30').hex()]).mode('lab').colors(5);
      const monochromatic = chroma.scale([chroma(sanitizedColor).brighten(2), chroma(sanitizedColor), chroma(sanitizedColor).darken(2)]).mode('lab').colors(5);
      const complementary = chroma.scale([sanitizedColor, chroma(sanitizedColor).set('hsl.h', '+180').hex()]).mode('lab').colors(5);
      const splitComplementary = chroma.scale([sanitizedColor, chroma(sanitizedColor).set('hsl.h', '+150').hex(), chroma(sanitizedColor).set('hsl.h', '+210').hex()]).mode('lab').colors(5);
      const triad = chroma.scale([sanitizedColor, chroma(sanitizedColor).set('hsl.h', '+120').hex(), chroma(sanitizedColor).set('hsl.h', '+240').hex()]).mode('lab').colors(5);

      console.log('Generated palettes:', { analogous, monochromatic, complementary, splitComplementary, triad });

      setPalettes([
        { name: 'Analogous', colors: analogous, description: 'Analogous palettes use colors that are next to each other on the color wheel.' },
        { name: 'Monochromatic', colors: monochromatic, description: 'Monochromatic palettes use variations in lightness and saturation of a single color.' },
        { name: 'Complementary', colors: complementary, description: 'Complementary palettes use colors that are opposite each other on the color wheel.' },
        { name: 'Split Complementary', colors: splitComplementary, description: 'Split Complementary palettes use a base color and two adjacent colors to its complement.' },
        { name: 'Triad', colors: triad, description: 'Triad palettes use three colors that are evenly spaced around the color wheel.' },
      ]);
      setError(null);
    } catch (error) {
      console.error('Error generating palettes:', error);
      setError('Error generating palettes');
    }
  };

  const handleColorChange = (e) => {
    const newColor = e.target.value;
    setBaseColor(newColor);
  };

  const copyToClipboard = (color) => {
    navigator.clipboard.writeText(color);
    alert(`${color} copied to clipboard`);
  };

  const getRandomColor = () => {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
    setBaseColor(randomColor);
  };

  const getTextColor = (color) => {
    return chroma(color).luminance() > 0.5 ? '#333' : '#fff';
  };

  return (
    <div className="color-palette-generator">
      <h2>Color Palette Generator</h2>
      <p>Enter a hex color code to generate different types of color palettes.</p>
      <input
        type="text"
        value={baseColor}
        onChange={handleColorChange}
        placeholder="Enter a hex color code"
      />
      {error && <p className="error">{error}</p>}
      <button className="random-color-button" onClick={getRandomColor}>Random Color</button>
      {palettes.map((palette) => (
        <div key={palette.name} className="palette">
          <h3>{palette.name}</h3>
          <p>{palette.description}</p>
          <div className="colors">
            {palette.colors.map((color) => (
              <div
                key={color}
                className="color-swatch"
                style={{ backgroundColor: color }}
                onClick={() => copyToClipboard(color)}
                title="Click to copy hex code"
              >
                <div className="color-hex" style={{ color: getTextColor(color) }}>{color}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <p className="description">This tool helps you create beautiful color palettes for your design projects. You can generate various types of palettes by entering a hex color code and exploring the different options provided.</p>
    </div>
  );
};

export default ColorPaletteGenerator;
