import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import axios from 'axios';
import './ColorPicker.css';

const ColorPicker = () => {
  const [color, setColor] = useState('#FFFFFF');
  const [colorName, setColorName] = useState('');
  const [textColor, setTextColor] = useState('#000000');

  useEffect(() => {
    fetchColorName(color);
    determineTextColor(color);
  }, [color]);

  const fetchColorName = async (hex) => {
    try {
      const response = await axios.get(`/api/colors/hex/${hex.substring(1)}`);
      if (response.data.name) {
        setColorName(response.data.name);
      } else {
        setColorName('');
      }
    } catch (error) {
      console.error('Error fetching color name:', error);
      setColorName('');
    }
  };

  const determineTextColor = (hex) => {
    const rgb = parseInt(hex.substring(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >>  8) & 0xff;
    const b = (rgb >>  0) & 0xff;
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    setTextColor(luma < 140 ? '#FFFFFF' : '#000000');
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  return (
    <div className="color-picker-container">
      <h1 className="title">Hexidecimal HTML Color Picker</h1>
      <p className="description">A versatile and user-friendly HTML Hex Color Picker allows users to select and manipulate colors in hexadecimal format. It provides a visual interface where users can choose from a spectrum of colors or input specific hex color codes. This tool is essential for web designers, developers, and anyone involved in creating digital content, as it helps ensure precise color selection and consistency across various design elements.</p>
      <div className="color-picker-content">
        <SketchPicker color={color} onChangeComplete={handleColorChange} />
        <div className="color-swatch" style={{ backgroundColor: color, color: textColor }}>
          <p>{color.toUpperCase()}</p>
          <p>{colorName}</p>
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;
