import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-menu">
          <ul>
            <li><a href="/color">All Colors</a></li>
             <li><a href="/color-a-to-z">Colors A to Z</a></li>
            <li><a href="/color-picker">Hex Color Picker</a></li>
            <li><a href="/color-palette-generator">Color Palette Generator</a></li>
          </ul>
        </div>
        <div className="footer-menu">
          <ul>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
          </ul>
        </div>
      </div>
      <p>&copy; 2024 Color Palettes. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;




